<script lang="ts" setup>
interface DialogProps {
    label?: string | undefined
    confirmLabel?: string | undefined
    noAction?: boolean
}

withDefaults(defineProps<DialogProps>(), {
    confirmLabel: 'Select',
})

const root = ref<HTMLDialogElement>()

function close() {
    root.value?.close()
}

function onClick(event: MouseEvent) {
    if (!root.value) return

    const rect = root.value.getBoundingClientRect()

    if (
        event.clientY < rect.top
        || event.clientY > rect.bottom
        || event.clientX < rect.left
        || event.clientX > rect.right
    ) {
        close()
    }
}

onMounted(() => {
    root.value?.showModal()
})
</script>

<template>
    <dialog
        ref="root"
        :class="$style.root"
        @click="onClick"
    >
        <div :class="$style.header">
            <p
                v-if="label"
                :class="$style.title"
            >
                {{ label }}
            </p>
            <slot name="before-close" />
            <VButton
                :class="$style.close"
                :aria-label="$t('close')"
                outlined
                size="s"
                autofocus
                @click="close"
            >
                <template #icon>
                    <SvgIcon
                        name="close-small"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                    />
                </template>
            </VButton>
        </div>
        <slot />
        <VButton
            v-if="!noAction"
            :class="$style.confirm"
            size="xl"
            filled
            :label="confirmLabel"
            @click="close"
        />
    </dialog>
</template>

<style lang="scss" module>
.root {
    display: flex;
    width: 50vw;
    max-width: 700px;
    flex-direction: column;
    padding: 0;
    border: 1px solid rgb(1 1 1 / 20%);
    border-radius: var(--radius-md);
    margin: auto;
    background-color: color(white);

    &::backdrop {
        background-color: rgb(0 0 0 / 60%);
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-md) var(--spacing-sm) var(--spacing-sm);
    border-bottom: 1px solid var(--color-line-primary, rgb(1 1 1 / 10%));
    gap: rem(12);
}

.title {
    margin: 0;
    font-size: 24px;
    font-style: italic;
    font-weight: 900;
    letter-spacing: 0.48px;
    line-height: 110%; /* 26.4px */
    text-transform: uppercase;
}

.confirm {
    align-self: flex-end;
    margin: var(--spacing-sm) var(--spacing-md);
}

.close {
    height: 32px;
    border: 1px solid var(--color-line-primary, rgb(1 1 1 / 15%));
}
</style>
